// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import "bootstrap";
import ("packs/navigation_bar")
import ("packs/dashboard_navbar")
import ("packs/booklet_pages")
require.context('../images', true)

Rails.start();